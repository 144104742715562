import myRequest from '../http/index.js'

export function getyzmAPI(params){
    return myRequest({
        url:"index.php/index/Txsms/smsCode",         
        params,
        method:"post",
        token:"notoken"
      })
}
export function registerAPI(params){
  return myRequest({
      url:"index.php/index/login/register",         
      params,
      method:"post",
      token:"notoken"
    })
}
export function loginpcAPI(params){
  return myRequest({
      url:"index.php/index/login/loginpc",         
      params,
      method:"post",
      token:"notoken"
    })
}
export function infoUserAPI(params){
  return myRequest({
      url:"index.php/index/userinfo/infoUser",         
      params,
      method:"get"
    })
}
// 密码重置
export function resetUserPwdAPI(params){
  return myRequest({
      url:"index.php/index/login/resetUserPwd",         
      params,
      method:"post"
    })
}
// 扫码登录
export function userAuthAPI(params){
  return myRequest({
      url:"index.php/index/login/userAuth",         
      params,
      method:"get"
    })
}
// 扫码登录
export function bindUserInfoAPI(params){
  return myRequest({
      url:"index.php/index/login/bindUserInfo",         
      params,
      method:"post"
    })
}
// 退出登录
export function logoutAPI(params){
  return myRequest({
      url:"/index.php/index/login/logout",         
      params,
      method:"get"
    })
}
